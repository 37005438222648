import { useState, useMemo, useCallback, useEffect, FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DataRow from '../shared/data-grid/DataRow';
import LockIcon from '../shared/icon/LockIcon';
import PageLoader from '../shared/page-loader/PageLoader';
import { Heading, HeadingSize } from '../shared/text/Heading';
import SortableHeading from '../shared/data-grid/SortableHeading';
import { ApiResponse } from '../../models/ApiResponse';
import CopyText from '../shared/CopyText';
import LinkIcon from '../shared/icon/LinkIcon';
import ClientPublicFormService from '../../services/ClientPublicFormService';
import { ClientPublicForm, PublicForm } from '../../models/ClientPublicForm';
import FormOwnership from '../ownership/FormOwnership';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import StringUtils from '../../utils/StringUtils';
import User from '../../models/User';
import ClientService from '../../services/ClientService';
import { ClientFormUser } from '../../models/ClientFormUser';
import { FormConfig } from '../../models/Form';
import InfoIcon from '../shared/icon/InfoIcon';
import DateIntervalEditor from './DateIntervalEditor';
import { DateInterval, DateIntervalKeys } from '../../models/DateInterval';
import { nextTick } from '../../utils/ReactUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { useCurrentClient } from '../../global-state/Clients';

const OrgPublicForms: FC = () => {
  const [loading, setLoading] = useState(true);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [clientPublicForms, setClientPublicForms] = useState<ApiResponse<ClientPublicForm[]> | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('+title');
  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({ ['title']: '+title' });
  const [clientUsers, setClientUsers] = useState<User[]>([]);
  const [selectedClientPublicForm, setSelectedClientPublicForm] = useState<ClientPublicForm | null>(null);
  const [openDateEditor, setOpenDateEditor] = useState<boolean>(false);

  const { t } = useTranslation(['organisation', 'common']);
  const client = useCurrentClient((x) => x.value);

  const formsFilter = useMemo(
    () => ({
      sortBy: sortBy,
      pageSize: 15,
      pageNumber: currentPage,
    }),
    [currentPage, sortBy],
  );

  const filterForms = useCallback(() => {
    if (client) {
      const scrollTop = pageContentRef.current?.scrollTop || 0;

      setLoading(true);
      ClientPublicFormService.getPublicForms(client?.id, formsFilter).then((res) => {
        setClientPublicForms((prev) => {
          const formsPaged = { ...res };
          if (prev?.data && formsFilter.pageNumber > 1) {
            formsPaged.data = [...prev.data, ...res.data];
          }
          return formsPaged;
        });
        setLoading(false);

        nextTick(() => {
          pageContentRef.current?.scrollTo({ top: scrollTop });
        });
      });
    }
  }, [client, formsFilter]);

  useEffect(() => {
    filterForms();
  }, [filterForms]);

  useEffect(() => {
    if (client) {
      ClientService.getUsers().then((res) => {
        setClientUsers(res);
      });
    }
  }, [client]);

  const createOrUpdate = useCallback(
    (publicForm: PublicForm, templateForm: Partial<FormConfig>) => {
      if (client) {
        if (publicForm.id) {
          ClientPublicFormService.updatePublicForm(publicForm.id, publicForm.configuration).then((res) => {
            onUpdated(res.data);
          });
        } else {
          ClientPublicFormService.createPublicForm({
            clientId: client.id,
            configuration: publicForm.configuration,
            templateFormId: templateForm.id || '',
            templateFormVersion: templateForm.version || 0,
          }).then((res) => {
            onUpdated(res.data);
          });
        }
      }
    },
    [client],
  );

  const onUpdated = (cpf: ClientPublicForm) => {
    setClientPublicForms((prev) => {
      if (prev) {
        return {
          ...prev,
          data: [...prev.data.map((x) => (x.templateForm.id === cpf.templateForm.id ? cpf : x))],
        };
      }
      return prev;
    });
  };

  const onUsersChange = (users: ClientFormUser[], cpf: ClientPublicForm) => {
    const publicForm = {
      ...cpf.publicForm,
      configuration: {
        ...cpf.publicForm?.configuration,
        formUsers: Object.assign({}, ...users.map((x) => ({ [x.id || '']: x.role }))),
      },
    } as PublicForm;

    createOrUpdate(publicForm, cpf.templateForm);
  };

  const lock = useCallback(
    (cpf: ClientPublicForm, isLocked: boolean) => {
      if (!client) {
        return;
      }

      const publicForm = {
        ...cpf.publicForm,
        configuration: {
          ...cpf.publicForm?.configuration,
          isLocked: isLocked,
        },
      } as PublicForm;

      createOrUpdate(publicForm, cpf.templateForm);
    },
    [client, createOrUpdate],
  );

  const onSortBy = (expression: string) => {
    setClientPublicForms(null);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const updateSortExpression = (field: string, expression: string) => {
    const expressions = { ...sortExpressions, [field]: expression };
    setSortExpressions(expressions);

    const fullExpression = Object.values(expressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSortBy(fullExpression.join(','));
  };

  const getUsers = useCallback(
    (publicForm?: PublicForm): ClientFormUser[] => {
      if (publicForm && clientUsers.length) {
        const users = Object.entries(publicForm.configuration.formUsers).map(([key, value]) => {
          const clientUser = clientUsers.find((user) => user.id && user.id === key);
          return { ...(clientUser as User), role: value };
        });
        return users;
      }
      return [];
    },
    [clientUsers],
  );

  const publicLink = (clientPublicForm: ClientPublicForm) => {
    if (clientPublicForm.publicForm && !clientPublicForm.publicForm.configuration.isLocked) {
      const isLocal = location.host.indexOf('localhost') !== -1;
      const baseUrl = isLocal ? 'http://localhost:3000' : `${location.protocol}//${location.host}`;
      const sanatizedTitle = LanguageUtils.getTranslation('title', clientPublicForm.templateForm?.translations ?? {})
        ?.replaceAll(/[^\w]/g, '-') // all non-word characters to `-`
        .replaceAll(/-{2,}/g, '-') // make multiple `-`'s into one `-`
        .replace(/^-?(.*?)-?$/, (_, text) => text) // remove leading & trailing `-`
        .toLowerCase();
      const publicLink = `${baseUrl}/public/${sanatizedTitle ?? 'untitled-form'}/${clientPublicForm.publicForm.id}`;
      return (
        <>
          <LinkIcon className="flex-shrink-0" />
          <CopyText text={publicLink} displayText={StringUtils.truncate(publicLink, 60)} />
        </>
      );
    }
    return null;
  };

  const setDueDate = (amount: number | null, interval: DateInterval | null) => {
    if (!selectedClientPublicForm) {
      return;
    }

    const publicForm = {
      ...selectedClientPublicForm.publicForm,
      configuration: {
        ...selectedClientPublicForm.publicForm?.configuration,
        dueDateInterval: interval,
        dueDateIntervalAmount: amount,
      },
    } as PublicForm;

    setOpenDateEditor(false);

    setSelectedClientPublicForm(null);

    createOrUpdate(publicForm, selectedClientPublicForm.templateForm);
  };

  return (
    <div className="h-full pt-6">
      <div>
        <Heading size={HeadingSize.H3}>{t('organisation:tabs.public-forms')}</Heading>
        <div className="pt-2">{t('organisation:public-forms.description')}</div>
      </div>
      <div className="my-3 h-full">
        <div className="-mx-6 h-full p-8">
          <PageLoader loading={loading}>
            <div className="flex pl-5 pr-3">
              <div className="w-1/3">
                <SortableHeading
                  title={t('organisation:public-forms.list.name')}
                  onSort={updateSortExpression}
                  expression={sortExpressions['title'] ?? '+title'}
                />
              </div>
              <div className="w-2/3">{t('organisation:public-forms.list.link')}</div>
              <div className="w-48 text-center">{t('organisation:public-forms.list.due')}</div>
              <div className="w-48 text-center">{t('organisation:public-forms.list.status')}</div>
              <div className="w-48 text-center">{t('organisation:public-forms.list.users')}</div>
              <div className="w-10">{/* SPACER */}</div>
            </div>
            <div>
              {clientPublicForms?.data?.map((cpf) => (
                <DataRow
                  key={cpf.templateForm.id}
                  contextMenuItems={[
                    {
                      title: t('organisation:public-forms.list.actions.set-due'),
                      onClick: () => {
                        setSelectedClientPublicForm(cpf);
                        setOpenDateEditor(true);
                      },
                      hide: !cpf.publicForm || (cpf.publicForm.configuration.dueDateIntervalAmount || 0) > 0,
                    },
                    {
                      title: t('organisation:public-forms.list.actions.edit-due'),
                      onClick: () => {
                        setSelectedClientPublicForm(cpf);
                        setOpenDateEditor(true);
                      },
                      hide: !cpf.publicForm || !cpf.publicForm.configuration.dueDateIntervalAmount || 0 > 0,
                    },
                    {
                      title: t('organisation:public-forms.list.actions.lock'),
                      onClick: () => lock(cpf, true),
                      hide: !cpf.publicForm || cpf.publicForm.configuration.isLocked,
                    },
                    {
                      title: t('organisation:public-forms.list.actions.unlock'),
                      onClick: () => lock(cpf, false),
                      hide: cpf.publicForm && !cpf.publicForm.configuration.isLocked,
                    },
                  ]}
                >
                  <div className={`w-1/3 font-medium ${!cpf.publicForm || cpf.publicForm.configuration.isLocked ? 'text-gray-300' : 'text-black'}`}>
                    {LanguageUtils.getTranslation('title', cpf.templateForm.translations || {})}
                  </div>
                  <div className="flex w-2/3 items-center gap-1">{publicLink(cpf)}</div>
                  <div className="w-48 text-center">
                    {cpf.publicForm?.configuration.dueDateIntervalAmount
                      ? `${cpf.publicForm.configuration.dueDateIntervalAmount} ${t(
                          DateIntervalKeys[cpf.publicForm.configuration.dueDateInterval || DateInterval.DAY],
                        )}`
                      : '-'}
                  </div>
                  <div className="w-48 text-center">
                    {(!cpf.publicForm || cpf.publicForm.configuration.isLocked) && <LockIcon className="h-4 w-4" />}
                  </div>
                  <div className="flex w-48 flex-col items-center">
                    <FormOwnership
                      users={getUsers(cpf.publicForm)}
                      size={OwnershipDisplayType.Tiny}
                      onUsersChange={(users: ClientFormUser[]) => {
                        onUsersChange(users, cpf);
                      }}
                      requiresApproval={!!cpf.templateForm.requiresApproval}
                      requiresValidation={!!cpf.templateForm.requiresValidation}
                      shouldValidateRoles={false}
                      viewOnly={!cpf.publicForm || cpf.publicForm.configuration.isLocked}
                      modalHeading={t('organisation:public-forms.default-users-heading')}
                    />
                  </div>
                </DataRow>
              ))}
              {clientPublicForms?.data?.length === 0 && (
                <div data-cy="public-forms-list-empty" className="flex h-full justify-center">
                  <div className="mt-36 text-center">
                    <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                    <Heading size={HeadingSize.H3} className="my-4">
                      {t('organisation:public-forms.list.empty.heading')}
                    </Heading>
                    <div className="text-dpm-20">{t('organisation:public-forms.list.empty.sub-heading')}</div>
                  </div>
                </div>
              )}
            </div>
            {selectedClientPublicForm && (
              <DateIntervalEditor
                title={t('organisation:public-forms.due-date-heading')}
                open={openDateEditor}
                amount={selectedClientPublicForm.publicForm?.configuration.dueDateIntervalAmount || 0}
                interval={selectedClientPublicForm.publicForm?.configuration.dueDateInterval || DateInterval.DAY}
                onClose={() => setOpenDateEditor(false)}
                onSave={(amount, interval) => setDueDate(amount, interval)}
              />
            )}
          </PageLoader>
        </div>
      </div>
    </div>
  );
};

export default OrgPublicForms;
